/* RESUME-HEADER*/
 li :visited, li :link, li :active, li :focus
{
    text-decoration: none;
}

li :visited {text-decoration: none; color:black; }
h3 :visited {text-decoration: none; color:black; }
li :hover { text-decoration: none; color:white; }

li {
	font-family: 'Roboto', sans-serif;	
	width: 100%;
}

.nav-container{
	z-index: 10;
}

/*TOP NAV*/	
.top-nav {
	position: fixed;
	width: 100vw;
	padding-left: 6.25vw;
	top: 5vh;
	z-index: 10;

}

.logo{
	width: 3.5%;
	border-radius: 100%;
	padding: 0 3px;
	background-color: rgba(255, 255, 255, 0.349);  
	transition: .3s ease;
}

.logo:hover{
	border-radius: 0;
	transition: .3s ease;
	background-color: rgba(0, 0, 0, 0.349);  

}
	
/*SIDE NAV*/	
.side-nav {
  list-style: none;
	margin-top: 20vh;
  position: fixed;
  right: 6.25%;
  transform: rotate(90deg);
	transform-origin: 100% 100%;
	z-index: 10;
	background-color: rgba(255, 255, 255, 0.349);  
	padding: 5px 10px 0 10px;
	transition: .3s ease;
	border-radius: 0%;
	color: black;
	
}

.side-nav:hover{
	transition: .3s ease;
	background-color: rgba(0, 0, 0, 0.349);
	border-radius: 20px;
	cursor: pointer;
}

@media only screen and (max-width: 1024px) {   
	.logo{
		width: 7%;
	}

	.side-nav{
		right: 12.5%;
 
	 }
}

@media only screen and (max-width: 600px) {   
	.logo{
		width: 11%;
	}

	.side-nav{
	 right: 12.5%;

	}

} 


