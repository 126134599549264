@import url(https://fonts.googleapis.com/css?family=Roboto:400,900);
@font-face {
  font-family: 'Lexia-Black';
  src: url(/static/media/Lexia-Black.bc6295e2.eot);
  src: url(/static/media/Lexia-Black.bc6295e2.eot?#iefix) format('embedded-opentype'),
      url(/static/media/Lexia-Black.d5d8b998.woff2) format('woff2'),
      url(/static/media/Lexia-Black.d1884de9.woff) format('woff'),
      url(/static/media/Lexia-Black.b15fa29f.ttf) format('truetype');
  font-weight: 900;
  font-style: normal;
}


@font-face {
  font-family: 'Lexia-Black-Italic';
  src: url(/static/media/Lexia-BlackItalic.d8087926.eot);
  src: url(/static/media/Lexia-BlackItalic.d8087926.eot?#iefix) format('embedded-opentype'),
      url(/static/media/Lexia-BlackItalic.4fdd5064.woff2) format('woff2'),
      url(/static/media/Lexia-BlackItalic.62c842ba.woff) format('woff'),
      url(/static/media/Lexia-BlackItalic.d40280a1.ttf) format('truetype');
}

*{
  margin:0;
  padding: 0;
  -webkit-overflow-scrolling: touch;
}

button:focus {outline:0;}
div:focus {outline:0;}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-size: 2.5em;
  font-family: 'Lexia-Black-Italic';
}

h2 {
  font-size: 1.5em;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;	
}

h3 {
  font-size: 1.3em;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;	

}

p {
  font-family: 'Roboto', sans-serif;
  font-size: 1.1em;

}

#site-link{
  margin-bottom: 2vh;
  padding: 0;
  text-decoration: underline;

}

#link-web{
  margin-top:3vh;
  text-decoration: underline;

}

#link-web2{
  margin-bottom:3vh;
  text-decoration: underline;
  color: white;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
/* RESUME-HEADER*/
 li :visited, li :link, li :active, li :focus
{
    text-decoration: none;
}

li :visited {text-decoration: none; color:black; }
h3 :visited {text-decoration: none; color:black; }
li :hover { text-decoration: none; color:white; }

li {
	font-family: 'Roboto', sans-serif;	
	width: 100%;
}

.nav-container{
	z-index: 10;
}

/*TOP NAV*/	
.top-nav {
	position: fixed;
	width: 100vw;
	padding-left: 6.25vw;
	top: 5vh;
	z-index: 10;

}

.logo{
	width: 3.5%;
	border-radius: 100%;
	padding: 0 3px;
	background-color: rgba(255, 255, 255, 0.349);  
	transition: .3s ease;
}

.logo:hover{
	border-radius: 0;
	transition: .3s ease;
	background-color: rgba(0, 0, 0, 0.349);  

}
	
/*SIDE NAV*/	
.side-nav {
  list-style: none;
	margin-top: 20vh;
  position: fixed;
  right: 6.25%;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
	-webkit-transform-origin: 100% 100%;
	        transform-origin: 100% 100%;
	z-index: 10;
	background-color: rgba(255, 255, 255, 0.349);  
	padding: 5px 10px 0 10px;
	transition: .3s ease;
	border-radius: 0%;
	color: black;
	
}

.side-nav:hover{
	transition: .3s ease;
	background-color: rgba(0, 0, 0, 0.349);
	border-radius: 20px;
	cursor: pointer;
}

@media only screen and (max-width: 1024px) {   
	.logo{
		width: 7%;
	}

	.side-nav{
		right: 12.5%;
 
	 }
}

@media only screen and (max-width: 600px) {   
	.logo{
		width: 11%;
	}

	.side-nav{
	 right: 12.5%;

	}

} 



.home{
    background-color: #7fffd4;
    height:100%;
    width:100%;
}

.frame{
    width: 100%;
    height: 100vh;
}

.frame2{
    height: 50vh;
    width: 100%;
    background-color: #7fffd4;
    position: fixed;
    bottom:0;
    margin-top: 100vh;
}

.hero-container{
    display: -webkit-flex;
    display: flex;
    height: 100vh;
    width: 100%;
    z-index: 1;
    position: fixed;
    margin-top: -100vh;
}

.hero-text-container{
    margin-top: 35vh;
    margin-left: 12%;
    position: absolute;
}

.hero-text{
    max-width: 80vw;
    letter-spacing: -1px;
}

.hero-subtext{
    margin-top: 1.4vh;
}

.social-media-container{
    height: 100vh;
    width: 100%;
    position: absolute;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
}

.social-media{
    height: 20vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
    padding-right: 12.5vw;
}

.social-icon{
    width: 2%;
}

#icon{
    padding-right: 4%;
}

@media only screen and (max-width: 1024px) {   
    .social-icon{
        width: 4%;
    }
    #icon{
        padding-right: 6%;
    }
  }

  @media only screen and (min-width: 768px) {   
    .hero-text-container{
        margin-left: 19%;
    }
  }

  @media only screen and (max-width: 600px) {
    .social-icon{
        width: 8%;
    }
    #icon{
        padding-right: 9%;
    }
  } 
  
  

.blob {
  position: absolute;
  top: 0;
  left: 0;
  fill: #39CDED;
  width: 50%;
  z-index: -1;
  -webkit-animation: move 20s ease-in-out infinite;
          animation: move 20s ease-in-out infinite;
}

@-webkit-keyframes move {
  0%   { -webkit-transform: scale(1); transform: scale(1);  }
  38%  { -webkit-transform: scale(0.8, 1) rotate(26 deg); transform: scale(0.8, 1) rotate(26 deg); }
  40%  { -webkit-transform: scale(0.8, 1)rotate(26deg); transform: scale(0.8, 1)rotate(26deg); }
  78%  { -webkit-transform: scale(1.3) rotate(-20deg); transform: scale(1.3) rotate(-20deg); }
  80%  { -webkit-transform: scale(1.3)rotate(-20deg); transform: scale(1.3)rotate(-20deg); }
  100% { -webkit-transform: scale(1) ; transform: scale(1) ;}
}

@keyframes move {
  0%   { -webkit-transform: scale(1); transform: scale(1);  }
  38%  { -webkit-transform: scale(0.8, 1) rotate(26 deg); transform: scale(0.8, 1) rotate(26 deg); }
  40%  { -webkit-transform: scale(0.8, 1)rotate(26deg); transform: scale(0.8, 1)rotate(26deg); }
  78%  { -webkit-transform: scale(1.3) rotate(-20deg); transform: scale(1.3) rotate(-20deg); }
  80%  { -webkit-transform: scale(1.3)rotate(-20deg); transform: scale(1.3)rotate(-20deg); }
  100% { -webkit-transform: scale(1) ; transform: scale(1) ;}
}



  
@media only screen and (max-width: 1024px) {
    .blob{
        width: 100%;
        top: 20%;
        left: -25%;      
    } 
}


a :visited, a :link, a :active, a :focus
{
    text-decoration: none;
}



a:visited path{
    fill: black;
}
a:hover path {
    fill: white;
    transition:  ease;
}
a:active path {
    fill: black;
}

#git{
  padding-bottom: 1%;
}

@-webkit-keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.bounceIn {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn;
}
  
  @-webkit-keyframes bounce {
    from,
    20%,
    53%,
    80%,
    to {
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  
    40%,
    43% {
      -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      -webkit-transform: translate3d(0, -30px, 0);
      transform: translate3d(0, -30px, 0);
    }
  
    70% {
      -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      -webkit-transform: translate3d(0, -15px, 0);
      transform: translate3d(0, -15px, 0);
    }
  
    90% {
      -webkit-transform: translate3d(0, -4px, 0);
      transform: translate3d(0, -4px, 0);
    }
  }
  
  @keyframes bounce {
    from,
    20%,
    53%,
    80%,
    to {
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  
    40%,
    43% {
      -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      -webkit-transform: translate3d(0, -30px, 0);
      transform: translate3d(0, -30px, 0);
    }
  
    70% {
      -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      -webkit-transform: translate3d(0, -15px, 0);
      transform: translate3d(0, -15px, 0);
    }
  
    90% {
      -webkit-transform: translate3d(0, -4px, 0);
      transform: translate3d(0, -4px, 0);
    }
  }
  
  .bounce {
    -webkit-animation-name: bounce;
    animation-name: bounce;
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
  }

  @-webkit-keyframes fadeInRight {
    from {
      opacity: 0;
      -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0);
    }
  
    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  
  @keyframes fadeInRight {
    from {
      opacity: 0;
      -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0);
    }
  
    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  
  .fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
  }
  
#main{
    font-size: 3em;

}

#submain{
    font-size: 1.8em;
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
}

h2 {
    margin-bottom: 1.5vh;
}

h3 {
    margin-bottom: 1.5vh;
}

a {
    text-decoration: none;
}

#mright{
    margin-left: 2vw;
}

.resume{
    background-color: white;
    height: 100%;
    position: relative;
}

.resume-container{
    height: auto;
    margin-top: 30vh;
    margin-left: 12.5%;
    margin-right: 12.5%;
    margin-bottom: 20vh;

}



.seperator{
    height: 3vh;
    width: 100%;
    max-width: 700px;
    border-top: 3px solid black;
}

.summary{
    max-width: 400px;
}

.experience{
    margin-top: 50px;

}

.skills{
    margin-top: 5vh;

}

.resume-download{
    width: 100%;
    height: 5vh;
    border: 3px solid black;
    border-radius: 3px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-content: center;
            align-content: center;
    -webkit-align-items: center;
            align-items: center;
    margin-top: 50px;
    transition: .3s ease;
    box-sizing: border-box;
}

.resume-download:hover{
    border: 5px solid black;
    transition: .3s ease;
}   

.skill-container{
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-content: center;
            align-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-flex-direction: row;
            flex-direction: row;
    margin-top: 5vh;
}

.skillA{
    width: 20%;
    height: 4vh;
    border: 3px solid black;
    margin: 2%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-content: center;
            align-content: center;
    -webkit-align-items: center;
            align-items: center;
    border-radius: 3px;

}

#c-name{
    margin:0;
    font-weight: 600;
}


.rcontent{
    margin-bottom: 50px;
}


@media only screen and (max-width: 1024px) {   
    
}

@media only screen and (max-width: 768px) {   
    .skillA{
        width: 40%;
    }
    #skill-container{
        width: 100%;
        -webkit-flex-direction: row;
                flex-direction: row;
    }

}

@media only screen and (max-width: 600px) {
    .skillA{
        width: 100%;
    }
} 
  
  
.fade-enter {
    opacity: 0.0;
  }

  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 400ms ease-in;
  }

  .fade-exit {
    opacity: 1;
  }

  .fade-exit.fade-exit-active {
    opacity: 0.01;
    transition: opacity 400ms ease-in;
  }

  div.fade-group {
    position: relative;
  }

  section.route-section {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }



  

