.blob {
  position: absolute;
  top: 0;
  left: 0;
  fill: #39CDED;
  width: 50%;
  z-index: -1;
  animation: move 20s ease-in-out infinite;
}

@keyframes move {
  0%   { transform: scale(1);  }
  38%  { transform: scale(0.8, 1) rotate(26 deg); }
  40%  { transform: scale(0.8, 1)rotate(26deg); }
  78%  { transform: scale(1.3) rotate(-20deg); }
  80%  { transform: scale(1.3)rotate(-20deg); }
  100% { transform: scale(1) ;}
}



  
@media only screen and (max-width: 1024px) {
    .blob{
        width: 100%;
        top: 20%;
        left: -25%;      
    } 
}

