.home{
    background-color: #7fffd4;
    height:100%;
    width:100%;
}

.frame{
    width: 100%;
    height: 100vh;
}

.frame2{
    height: 50vh;
    width: 100%;
    background-color: #7fffd4;
    position: fixed;
    bottom:0;
    margin-top: 100vh;
}

.hero-container{
    display: flex;
    height: 100vh;
    width: 100%;
    z-index: 1;
    position: fixed;
    margin-top: -100vh;
}

.hero-text-container{
    margin-top: 35vh;
    margin-left: 12%;
    position: absolute;
}

.hero-text{
    max-width: 80vw;
    letter-spacing: -1px;
}

.hero-subtext{
    margin-top: 1.4vh;
}

.social-media-container{
    height: 100vh;
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column-reverse;
}

.social-media{
    height: 20vh;
    display: flex;
    flex-direction: row-reverse;
    padding-right: 12.5vw;
}

.social-icon{
    width: 2%;
}

#icon{
    padding-right: 4%;
}

@media only screen and (max-width: 1024px) {   
    .social-icon{
        width: 4%;
    }
    #icon{
        padding-right: 6%;
    }
  }

  @media only screen and (min-width: 768px) {   
    .hero-text-container{
        margin-left: 19%;
    }
  }

  @media only screen and (max-width: 600px) {
    .social-icon{
        width: 8%;
    }
    #icon{
        padding-right: 9%;
    }
  } 
  
  
