#main{
    font-size: 3em;

}

#submain{
    font-size: 1.8em;
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
}

h2 {
    margin-bottom: 1.5vh;
}

h3 {
    margin-bottom: 1.5vh;
}

a {
    text-decoration: none;
}

#mright{
    margin-left: 2vw;
}

.resume{
    background-color: white;
    height: 100%;
    position: relative;
}

.resume-container{
    height: auto;
    margin-top: 30vh;
    margin-left: 12.5%;
    margin-right: 12.5%;
    margin-bottom: 20vh;

}



.seperator{
    height: 3vh;
    width: 100%;
    max-width: 700px;
    border-top: 3px solid black;
}

.summary{
    max-width: 400px;
}

.experience{
    margin-top: 50px;

}

.skills{
    margin-top: 5vh;

}

.resume-download{
    width: 100%;
    height: 5vh;
    border: 3px solid black;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-top: 50px;
    transition: .3s ease;
    box-sizing: border-box;
}

.resume-download:hover{
    border: 5px solid black;
    transition: .3s ease;
}   

.skill-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 5vh;
}

.skillA{
    width: 20%;
    height: 4vh;
    border: 3px solid black;
    margin: 2%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    border-radius: 3px;

}

#c-name{
    margin:0;
    font-weight: 600;
}


.rcontent{
    margin-bottom: 50px;
}


@media only screen and (max-width: 1024px) {   
    
}

@media only screen and (max-width: 768px) {   
    .skillA{
        width: 40%;
    }
    #skill-container{
        width: 100%;
        flex-direction: row;
    }

}

@media only screen and (max-width: 600px) {
    .skillA{
        width: 100%;
    }
} 
  
  