@import url('https://fonts.googleapis.com/css?family=Roboto:400,900');

@font-face {
  font-family: 'Lexia-Black';
  src: url('./fonts/Lexia-Black.eot');
  src: url('./fonts/Lexia-Black.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Lexia-Black.woff2') format('woff2'),
      url('./fonts/Lexia-Black.woff') format('woff'),
      url('./fonts/Lexia-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}


@font-face {
  font-family: 'Lexia-Black-Italic';
  src: url('./fonts/Lexia-BlackItalic.eot');
  src: url('./fonts/Lexia-BlackItalic.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Lexia-BlackItalic.woff2') format('woff2'),
      url('./fonts/Lexia-BlackItalic.woff') format('woff'),
      url('./fonts/Lexia-BlackItalic.ttf') format('truetype');
}

*{
  margin:0;
  padding: 0;
  -webkit-overflow-scrolling: touch;
}

button:focus {outline:0;}
div:focus {outline:0;}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-size: 2.5em;
  font-family: 'Lexia-Black-Italic';
}

h2 {
  font-size: 1.5em;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;	
}

h3 {
  font-size: 1.3em;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;	

}

p {
  font-family: 'Roboto', sans-serif;
  font-size: 1.1em;

}

#site-link{
  margin-bottom: 2vh;
  padding: 0;
  text-decoration: underline;

}

#link-web{
  margin-top:3vh;
  text-decoration: underline;

}

#link-web2{
  margin-bottom:3vh;
  text-decoration: underline;
  color: white;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}